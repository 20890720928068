@import '../../../../assets/scss/dimensions';
@import '../../../../assets/scss/numbers';
@import '../../../../assets/scss/colors';

.action-icon-resume {
  width: 72px;
  height: 45px;
  margin: 1.3px 10px 3.7px 0;
  object-fit: contain;
  content: url('../../../../assets/images/resume-icon.png');
}

.action-item {
  display: flex;
  align-items: center;
  width: fit-content;
  font-family: SourceSansPro, sans-serif;
  font-size: $actions-item-font-size;
  font-weight: $primary-font-weight;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: $primary-text-color;
  white-space: nowrap;
  padding-left: $actions-strip-items-margin-padding;
  padding-right: 33px;
}

.left-most-action-item {
  display: flex;
  align-items: center;
  min-width: 78px;
  max-width: 78px;
  font-family: SourceSansPro, sans-serif;
  font-size: $actions-item-font-size;
  font-weight: $primary-font-weight;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: $primary-text-color;
  margin-right: $actions-strip-items-margin-padding;
  white-space: nowrap;
  overflow: hidden;
}

.left-most-action-item-resume {
  margin-left: -35px;
  margin-right: 35px;
  margin-top: 19px;
  max-width: 72px;
  max-height: 45px;
  overflow: hidden;
  content: url('../../../../assets/images/resume-icon.png');
}

.highlighted-action-item {
  display: flex;
  align-items: center;
  min-width: 510px;
  height: 80px;
  margin-left: $actions-strip-items-margin-padding;
  font-family: SourceSansPro, sans-serif;
  font-size: $actions-item-font-size;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: $primary-text-color;
  overflow: hidden;
}

@media only screen and (max-height: 720px) {
  .action-icon-resume {
    width: 48px;
    height: 30px;
    margin: 1.3px 10px 3.7px 0;
    content: url('../../../../assets/images/720p/resume-icon.svg');
  }

  .action-item {
    font-size: $actions-item-font-size-720p;
    font-weight: $primary-font-weight;
    padding-left: $actions-strip-items-margin-padding-720p;
    padding-right: $actions-strip-items-margin-padding-720p;
  }

  .left-most-action-item {
    min-width: 52px;
    max-width: 52px;
    font-size: $actions-item-font-size-720p;
    font-weight: $primary-font-weight;
    margin-right: $actions-strip-items-margin-padding-720p;
  }

  .left-most-action-item-resume {
    margin-left: 20px;
    margin-top: 10px;
    max-width: 48px;
    max-height: 30px;
    content: url('../../../../assets/images/720p/resume-icon.svg');
  }

  .highlighted-action-item {
    min-width: 358px;
    max-width: 358px;
    height: 53px;
    margin-left: $actions-strip-items-margin-padding-720p;
    padding-left: 11px;
    font-size: $actions-item-font-size-720p;
  }
}
